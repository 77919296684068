exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-admin-index-tsx": () => import("./../../../src/pages/admin/index.tsx" /* webpackChunkName: "component---src-pages-admin-index-tsx" */),
  "component---src-pages-admin-quota-tsx": () => import("./../../../src/pages/admin/quota.tsx" /* webpackChunkName: "component---src-pages-admin-quota-tsx" */),
  "component---src-pages-admin-registrants-tsx": () => import("./../../../src/pages/admin/registrants.tsx" /* webpackChunkName: "component---src-pages-admin-registrants-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-manual-tsx": () => import("./../../../src/pages/manual.tsx" /* webpackChunkName: "component---src-pages-manual-tsx" */),
  "component---src-pages-register-tsx": () => import("./../../../src/pages/register.tsx" /* webpackChunkName: "component---src-pages-register-tsx" */),
  "component---src-pages-volunteers-register-tsx": () => import("./../../../src/pages/volunteers/register.tsx" /* webpackChunkName: "component---src-pages-volunteers-register-tsx" */)
}

